<template>
    <div>
        <a-form-model-item
            label="Задача"
            prop="parent"
            class="mt-2"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 12 }"
            labelAlign="left">
            <div class="popover_input ant-input flex items-center relative ant-input-lg truncate">
                <a-tooltip
                    v-if="relObject"
                    :title="relObject.name"
                    class="mr-2 truncate">
                    <a-tag
                        color="blue"
                        class="tag_block truncate"
                        @click="taskDrawer = true">
                        {{relObject.name}}
                    </a-tag>
                </a-tooltip>
                <a-button
                    @click="taskDrawer = true"
                    type="link"
                    :icon="!relObject && 'plus'"
                    class="px-0">
                    {{relObject ? $t('task.change') : $t('task.select')}}
                </a-button>
                <a-button
                    v-if="relObject"
                    @click="clearSelect()"
                    type="link"
                    icon="close-circle"
                    class="px-0 text-current remove_parent" />
            </div>
        </a-form-model-item>
        <TaskSelectDrawer
            v-model="relObject"
            :taskDrawer="taskDrawer"
            :selectParentTask="selectParentTask"
            :closeHandler="closeHandler" />
    </div>
</template>

<script>
import TaskSelectDrawer from '@apps/vue2TaskComponent/components/EditDrawer/TaskSelectDrawer.vue'
export default {
    props: {
        widget: {
            type: Object,
            required: true
        },
        closeSettingDrawer: {
            type: Function,
            default: () => {}
        }
    },
    components: {
        TaskSelectDrawer
    },
    computed: {
        related_object() {
            return this.widget.random_settings?.related_object || null
        }
    },
    data() {
        return {
            relObject: null,
            taskDrawer: false
        }
    },
    created() {
        if(this.related_object) {
            this.relObject = this.related_object
        }
    },
    methods: {
        clearSelect() {
            this.relObject = null
        },
        selectParentTask() {

        },
        closeHandler() {
            this.taskDrawer = false
        },
        async saveConfig() {
            try {
                await this.$http.patch(`/widgets/user_widgets_on_desktop/${this.widget.id}/`, {
                    random_settings: {
                        related_object: this.relObject || null
                    }
                })
                this.$store.commit('dashboard/UPDATE_ACTIVE_WIDGET', {
                    widgetId: this.widget.id, 
                    key: 'random_settings', 
                    value: {
                        related_object: this.relObject || null
                    }
                })
                this.closeSettingDrawer()
            } catch(e) {
                console.log(e)
                this.$message.error('Ошибка')
            }
        },
        async resetConfig() {
            try {
                await this.$http.patch(`/widgets/user_widgets_on_desktop/${this.widget.id}/`, {
                    random_settings: {
                        related_object: null
                    }
                })
                this.$store.commit('dashboard/UPDATE_ACTIVE_WIDGET', {
                    widgetId: this.widget.id, 
                    key: 'random_settings', 
                    value: {
                        related_object: null
                    }
                })
                this.closeSettingDrawer()
            } catch(e) {
                console.log(e)
                this.$message.error('Ошибка')
            }
        }
    }
}
</script>